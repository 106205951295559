<template>
  <section id="quendrox-consulting">
    <img
      src="@/assets/images/quendrox-solution.jpg"
      alt="Quendrox HA"
      class="center"
    />
    <div class="readFriendlyLayout">
      <h2>Quendrox conslulting</h2>
      <blockquote>
        "The provision to businesses of objective advice and assistance relating
        the strategy, structure, management and operations of an organisation in
        pursuit of its long-term purposes and objectives. Such assistance may
        include the identification of options with recommendations; the
        provision of additional resources; and/or the implementation of
        solutions."
      </blockquote>
      <p>
        In short, a consultant provides external advice for organisations that
        require specialist expertise or an objective outside perspective on
        business.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "QuendroxConsulting"
};
</script>

<style scoped lang="scss">
img {
  border-radius: 10px;
}
</style>
